import _debounce from 'lodash/debounce.js';
import _each from 'lodash/each.js';
import _isEqual from 'lodash/isEqual.js';
import _pick from 'lodash/pick.js';
import _throttle from 'lodash/throttle.js';
import moment from 'moment-timezone';
import {
    getStore,
    pauseUpdates,
    resumeUpdates,
    resumeUpdatesQuiet,
} from '~common/store';
import history, {
    isDetailPage,
    getWorkspacePathParams,
    isIntegrationCalendarSettingsPage,
    pushTo,
    isViewingMeeting,
    isViewingOccurrence,
} from '~modules/navigation';
import {
    EVENT_REPLAY_START,
    EVENT_REPLAY_DONE,
    WEBSOCKET_SUBSCRIBE,
    WEBSOCKET_UNSUBSCRIBE,
    ADD_OCCURRENCE_WEBSOCKET,
    ADD_OCCURRENCES_WEBSOCKET,
    EDIT_MEETING_WEBSOCKET,
    EDIT_OCCURRENCES_WEBSOCKET,
    EDIT_GROUP_WEBSOCKET,
    ADD_GROUP_WEBSOCKET,
    DELETE_GROUP_WEBSOCKET,
    LINK_DOCUMENTS_WEBSOCKET,
    UNLINK_DOCUMENTS_WEBSOCKET,
    UPDATE_OCCURRENCE_ATTENDANCE_WEBSOCKET,
    RENUMBER_OCCURRENCE_MINUTES_WEBSOCKET,
    TOGGLE_OCCURRENCE_MINUTES_STATUS_WEBSOCKET,
    UPDATE_USERPROFILE_WEBSOCKET,
    UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET,
    INTEGRATION_PROFILE_CREATED,
    INTEGRATION_PROFILE_UPDATED,
    INTEGRATION_CALENDAR_LINKS_CREATED,
    INTEGRATION_CALENDAR_LINKS_DELETED,
    REMOTE_CALENDAR_EVENTS_UPDATED,
    REMOTE_CALENDAR_EVENTS_DELETED,
    REMOTE_CALENDAR_EVENT_HIDDEN,
    NOTIFICATION_DELETE,
    USER_JOIN_WEBSOCKET,
    USER_LEAVE_WEBSOCKET,
    USER_TOPIC_CHANGE_WEBSOCKET,
    WEBSOCKET_DISCONNECTED,
    PAGEVIEW_CREATED,
    PAGEVIEW_UPDATED,
    PAGEVIEW_META_UPDATED,
    WORKSPACE_MEMBER_ADDED,
    WORKSPACE_MEMBER_UPDATED,
    WORKSPACE_MEMBER_DELETED,
    WORKSPACE_MEMBER_LIST_RESET,
    WORKSPACE_INVOICE_CREATED,
    WORKSPACE_INVOICE_UPDATED,
    ADD_TEMPLATE_WEBSOCKET,
    UPDATE_TEMPLATE_WEBSOCKET,
    DELETE_TEMPLATE_WEBSOCKET,
    USER_TYPING_WEBSOCKET,
    INTEGRATION_CRM_LINKS_LOADED,
    INTEGRATION_CRM_LINKS_DELETED,
    CRM_CUSTOMER_DELETED,
    WORKSPACE_CRM_ACCESS_REMOVED,
    MOVE_OCCURRENCE_DELETE,
    MOVE_OCCURRENCE_UPDATE,
    USER_ACTIVELOCATION_WEBSOCKET,
} from '~common/action.types';
import {
    publish as wsPublish,
    subscribe as wsSubscribe,
    unsubscribe as wsUnsubscribe,
    setLastEventTime,
    getLastEventTime,
} from './subscriptions.js';
import {
    onMinuteItemCreateSuccess,
    onMinuteItemEditSuccess,
    onMinuteItemDeleteSuccess,
} from '~modules/minutes/minutes.actions';
import {
    deleteDocumentSuccess,
    documentUploadSuccess,
    editDocument,
} from '~modules/documents/document.actions';
import {
    onOccurrenceUpdateSuccess,
    onOccurrencesDelete,
    loadActivityCount,
    loadOccurrenceActivity,
    loadOccurrencePermissions,
} from '~modules/occurrence/occurrence.actions';
import {
    addActionItemSuccess,
    deleteActionItemSuccess,
    updateActionItemSuccess,
} from '~modules/actionItems/actionItem.actions';
import { getAgendaItemsForMeetingSelector } from '~modules/agenda/agenda.selectors';
import { clearCacheMeetingAddEditFormInitialValues } from '~modules/meeting/meeting.selectors';
import { clearCacheOccurrenceAddEditFormInitialValues } from '~modules/occurrence/occurrence.selectors';
import {
    getMeetingAccessLevel,
    getWorkspaceAccessLevel,
} from '~modules/permission/permission.helpers';
import { isAgendaTemplate } from '~modules/template/template.helpers';
import {
    ensureUsers,
    preloadMeetingData,
} from '~modules/preload/preload.actions';
import {
    onAgendaItemCreateSuccess,
    onAgendaItemEditSuccess,
    onAgendaItemDeleteSuccess,
    onAgendaItemRenumberSuccessWebsocket,
} from '~modules/agenda/agenda.actions';
import {
    onMeetingAddSuccess,
    onMeetingUpdateSuccess,
    onMeetingDelete,
    loadMeetingPermissions,
} from '~modules/meeting/meeting.actions';
import {
    onPermissionCreate,
    onPermissionDelete,
    onPermissionUpdate,
} from '~modules/permission/permission.actions';
import {
    onNotificationCreateSuccess,
    onNotificationUpdateSuccess,
} from '~modules/notification/notification.actions';
import {
    addCommentSuccess,
    deleteCommentSuccess,
} from '~modules/comment/comment.actions';
import { commentModel } from '~modules/comment/comment.mappers';
import { onMinutesPreviewCreated } from '~modules/modals/documentPreviewModal.actions';
import {
    onPrivateNotesCreateSuccess,
    onPrivateNotesEditSuccess,
} from '~modules/privatenotes/privateNotes.actions';
import { getLinkCount } from '~modules/integration/integration.actions';
import { clearMyHomepageIfContainsId } from '~modules/pageView/pageView.actions';
import {
    meetingModel,
    occurrenceModel,
    occurrenceModelList,
} from '~modules/meeting/meeting.mappers';
import {
    minuteItemModel,
    minuteItemModelList,
} from '~modules/minutes/minutes.mappers';
import { actionItemModel } from '../api/actionItem.mappers.js';
import { notificationModel } from '~modules/notification/notification.mappers';
import { get } from '../api/helpers.js';
import {
    pageViewModel,
    pageViewModelList,
} from '~modules/pageView/pageView.mappers';
import {
    alertPersistedError,
    removeAlert,
    alertPersistedInfo,
    alertSuccess,
} from '~modules/alert/alert.actions';
import {
    getPageState,
    getDashboardStateForWorkspace,
} from '~modules/pageView/pageView.selectors';
import { loadWorkspacePermissions } from '~modules/workspace/workspace.actions';
import { workspaceInvoice } from '~modules/workspace/workspace.mappers';
import { eventModelList } from '~modules/remoteCalendar/remoteCalendar.mappers';
import {
    loadRemoteCalendarEvents,
    resetRemoteCalendarEvents,
} from '~modules/remoteCalendar/remoteCalendar.actions';
import {
    loadCRMLinks,
    loadCRMInfo,
} from '~modules/remoteCRM/remoteCRM.actions';
import {
    INTEGRATION_TYPE_CALENDARS,
    INTEGRATION_TYPE_CRM,
    INTEGRATION_TYPE_TASKS,
    PERMISSION_TYPE_MEETING,
} from '~common/constants';
import {
    checkClientVersion,
    onInvalidSessionError,
    onUserDetailsUpdatedNotification,
} from '~client/actions/session';
import {
    hasIntegrationProfileGotScopes,
    integrationProfileStatusMeta,
    isCalendarIntegration,
    isCRMIntegration,
    isAuthenticationIntegration,
} from '~shared/integration/integration.helper';
import { ROUTES } from '~shared/navigation/routes';
import { getFilesConfig } from '~modules/remoteFiles/remoteFiles.mappers.js';
import { verifyFileLinkForMeeting } from '~modules/remoteFiles/remoteFiles.actions.js';

const THREE_DAYS_IN_SECONDS = 60 * 60 * 24 * 3;

const onDisconnect = _debounce(() => {
    const { dispatch } = getStore();
    dispatch(
        alertPersistedError({
            id: 'websocket-disconnected',
            title: 'You are currently offline',
            message: `You can't save any changes, but you can still review minutes.`,
        })
    );
}, 3000);

const closeEventReplayEnd = _debounce(() => {
    const { dispatch } = getStore();
    dispatch(
        removeAlert({
            id: 'event-replay-end',
        })
    );
}, 10000);

let prevActivityOccurrenceId;
const onOccurrenceActivityUpdate = _debounce(
    (occurrenceId) => {
        const { dispatch } = getStore();
        dispatch(loadActivityCount({ id: occurrenceId }));
    },
    5000,
    {
        leading: true,
    }
);
let prevLoadOccurrenceActivityId;
const throttleLoadOccurrenceActivity = _throttle((activityId, occurrenceId) => {
    const { dispatch } = getStore();
    dispatch(loadOccurrenceActivity({ activityId, occurrenceId }));
}, 5000);

function createSocketSubscriptions(dispatch, getState) {
    return {
        ['minutes:pdf-preview'](ctx, payload) {
            const { occurrence } = payload || {};
            dispatch(
                onMinutesPreviewCreated({
                    ...payload,
                    occurrence: occurrenceModel(occurrence),
                })
            );
        },

        ['comment:create'](ctx, payload) {
            dispatch(addCommentSuccess(commentModel(payload)));
        },

        ['comment:delete'](ctx, payload) {
            dispatch(deleteCommentSuccess(commentModel(payload)));
        },

        ['action_item:create'](ctx, payload) {
            const { user } = getState();
            dispatch(
                addActionItemSuccess(payload.occurrence, {
                    isLastUpdatedByOtherUser: ctx.user.id !== user.id,
                    ...actionItemModel(payload),
                })
            );
        },

        ['action_item:update'](ctx, payload) {
            const { user } = getState();
            dispatch(
                updateActionItemSuccess(payload.occurrence, {
                    isLastUpdatedByOtherUser: ctx.user.id !== user.id,
                    ...actionItemModel(payload),
                })
            );
        },

        ['action_item:delete'](ctx, payload) {
            dispatch(deleteActionItemSuccess(payload.occurrence, payload));
        },

        ['group:create'](ctx, payload) {
            dispatch({
                type: ADD_GROUP_WEBSOCKET,
                group: payload,
            });
        },

        ['group:update'](ctx, payload) {
            dispatch({
                type: EDIT_GROUP_WEBSOCKET,
                group: payload,
            });
        },

        ['group:delete'](ctx, payload) {
            dispatch({
                type: DELETE_GROUP_WEBSOCKET,
                group: payload,
            });

            dispatch(clearMyHomepageIfContainsId(payload.id));
        },

        ['meeting:create'](ctx, payload) {
            dispatch(ensureUsers(payload));
            dispatch(onMeetingAddSuccess(meetingModel(payload)));
        },

        ['meeting:delete'](ctx, meeting) {
            dispatch(onMeetingDelete(meeting));
        },

        ['meeting:update'](ctx, payload, prev) {
            //GOTCHA could be if user is removed as attendee, and permissions are removed.
            //       permission:delete should already have been processed.
            const { canView } = getMeetingAccessLevel(
                getState(),
                payload.id,
                getState().user
            );
            if (canView) {
                dispatch(ensureUsers(payload, prev));
                dispatch(onMeetingUpdateSuccess(meetingModel(payload)));

                // If user is on a meeting detail page and it is the same meeting and the fileLink has changed, then reload the fileLink access check
                const detailPage = isDetailPage(location.pathname);
                if (
                    !_isEqual(prev?.fileLink, payload?.fileLink) &&
                    detailPage
                ) {
                    const pageState = getPageState(getState(), detailPage.id);
                    if (pageState?.meeting === payload.id) {
                        dispatch(verifyFileLinkForMeeting(payload));
                    }
                }
            }
        },
        ['meeting:update:calendarLinks'](ctx, payload) {
            dispatch({
                type: EDIT_MEETING_WEBSOCKET,
                meeting: meetingModel(payload),
            });
        },

        ['occurrence:create'](ctx, payload) {
            dispatch(ensureUsers(payload));
            dispatch({
                type: ADD_OCCURRENCE_WEBSOCKET,
                occurrence: occurrenceModel(payload),
            });
        },

        ['occurrence:update'](ctx, payload, prev) {
            dispatch(ensureUsers(payload, prev));
            dispatch(onOccurrenceUpdateSuccess(occurrenceModel(payload), prev));
        },
        async ['occurrence:move:create'](ctx, occurrence, moveMeta) {
            const { targetMeeting } = moveMeta;
            const meeting = meetingModel(targetMeeting);
            await Promise.all([
                dispatch(ensureUsers(meeting)),
                dispatch(preloadMeetingData(meeting)),
            ]);
        },
        ['occurrence:move:delete'](ctx, occurrence, moveMeta) {
            const {
                targetMeeting,
                updatedMinuteItems,
                updatedActionItemIds,
                updatedCommentIds,
                updatedDocumentIds,
                updatedIntegrationLinkIds,
                updatedPrivateNotesIds,
                updatedPageViewIds,
            } = moveMeta;

            dispatch({
                type: MOVE_OCCURRENCE_DELETE,
                occurrence: occurrenceModel(occurrence),
                meeting: meetingModel(targetMeeting),
                minuteItemIds: updatedMinuteItems.map(
                    (minuteItem) => minuteItem.id
                ),
                actionItemIds: updatedActionItemIds,
                commentIds: updatedCommentIds,
                documentIds: updatedDocumentIds,
                integrationLinkIds: updatedIntegrationLinkIds,
                privateNotesIds: updatedPrivateNotesIds,
                pageViewIds: updatedPageViewIds,
            });
        },
        ['occurrence:move:update'](ctx, occurrence, moveMeta) {
            const {
                targetMeeting,
                updatedMinuteItems,
                updatedPermissions,
                updatedActionItemIds,
                updatedCommentIds,
                updatedDocumentIds,
                updatedIntegrationLinkIds,
                updatedPrivateNotesIds,
                updatedPageViewIds,
            } = moveMeta;

            dispatch({
                type: MOVE_OCCURRENCE_UPDATE,
                occurrence: occurrenceModel(occurrence),
                meeting: meetingModel(targetMeeting),
                minuteItems: minuteItemModelList(updatedMinuteItems),
                permissions: updatedPermissions,
                actionItemIds: updatedActionItemIds,
                commentIds: updatedCommentIds,
                documentIds: updatedDocumentIds,
                integrationLinkIds: updatedIntegrationLinkIds,
                privateNotesIds: updatedPrivateNotesIds,
                pageViewIds: updatedPageViewIds,
            });

            // If you're viewing the occurrence that's been moved, we need to reload the permissions in pageState
            if (isViewingMeeting({ id: occurrence.meeting })) {
                dispatch(loadMeetingPermissions({ id: occurrence.meeting }));

                if (isViewingOccurrence(occurrence)) {
                    dispatch(loadOccurrencePermissions(occurrence));
                    // wsPublish(`client:user:page:join`, {
                    //     activePage: {
                    //         objectType: 'occurrence',
                    //         state: {
                    //             occurrence: occurrence.id,
                    //             meeting: occurrence.meeting,
                    //             minuteItemIds,
                    //             visibility: isVisible
                    //                 ? DOCUMENT_VISIBILITY_VISIBLE
                    //                 : DOCUMENT_VISIBILITY_HIDDEN,
                    //         },
                    //     },
                    // });
                }
            }
        },

        ['occurrences:create'](ctx, payload) {
            _each(payload, (p) => dispatch(ensureUsers(p)));

            dispatch({
                type: ADD_OCCURRENCES_WEBSOCKET,
                occurrences: occurrenceModelList(payload),
            });
        },

        ['occurrences:update'](ctx, payload) {
            _each(payload, (p) => dispatch(ensureUsers(p)));

            clearCacheOccurrenceAddEditFormInitialValues();

            dispatch({
                type: EDIT_OCCURRENCES_WEBSOCKET,
                occurrences: occurrenceModelList(payload),
            });
        },
        ['occurrences:update:calendarLinks'](ctx, payload) {
            dispatch({
                type: EDIT_OCCURRENCES_WEBSOCKET,
                occurrences: occurrenceModelList(payload),
            });
        },

        ['occurrences:delete'](ctx, payload) {
            dispatch(onOccurrencesDelete(payload));
        },

        ['agenda:create'](ctx, payload) {
            dispatch(onAgendaItemCreateSuccess(payload));
        },

        ['agenda:createmultiple'](ctx, agendaItems) {
            pauseUpdates();
            agendaItems.forEach((agendaItem) =>
                dispatch(onAgendaItemCreateSuccess(agendaItem))
            );
            resumeUpdates();
        },

        ['template:create'](ctx, template) {
            const existingMeetingAgendaItems = isAgendaTemplate(template)
                ? getAgendaItemsForMeetingSelector(getState(), {
                      meeting: template.meetingId,
                  })
                : [];

            dispatch({
                type: ADD_TEMPLATE_WEBSOCKET,
                template,
                existingMeetingAgendaItems,
            });
        },
        ['template:update'](ctx, template) {
            dispatch({
                type: UPDATE_TEMPLATE_WEBSOCKET,
                template,
            });
        },
        ['template:delete'](ctx, template) {
            dispatch({
                type: DELETE_TEMPLATE_WEBSOCKET,
                template,
            });
        },
        ['agenda:update'](ctx, agendaItem) {
            dispatch(
                onAgendaItemEditSuccess(agendaItem.meeting, { agendaItem })
            );
        },

        ['agenda:delete'](ctx, agendaItem) {
            dispatch(onAgendaItemDeleteSuccess(agendaItem));
        },
        ['agenda:deletemultiple'](ctx, agendaItems) {
            dispatch(onAgendaItemDeleteSuccess(agendaItems));
        },

        ['agenda:renumbered'](ctx, payload) {
            dispatch(
                onAgendaItemRenumberSuccessWebsocket(
                    payload.meeting,
                    payload.changedItems
                )
            );
        },

        ['occurrence:minutes:create'](ctx, payload) {
            dispatch(
                onMinuteItemCreateSuccess(
                    payload.occurrence,
                    minuteItemModel(payload)
                )
            );
        },

        ['occurrence:minutes:createmultiple'](ctx, minuteItems) {
            pauseUpdates();
            minuteItems.forEach((minuteItem) =>
                dispatch(
                    onMinuteItemCreateSuccess(
                        minuteItem.occurrence,
                        minuteItemModel(minuteItem)
                    )
                )
            );
            resumeUpdates();
        },

        ['occurrence:minutes:update'](ctx, payload) {
            dispatch(
                onMinuteItemEditSuccess(
                    payload.occurrence,
                    minuteItemModel(payload)
                )
            );

            const { user } = ctx;
            dispatch({
                type: USER_TYPING_WEBSOCKET,
                userId: user.id,
                isTyping: false,
            });
        },

        ['occurrence:minutes:delete'](ctx, payload) {
            dispatch(onMinuteItemDeleteSuccess(payload.occurrence, payload));
        },
        ['occurrence:minutes:deletemultiple'](ctx, minuteItems) {
            if (minuteItems.length === 0) return;
            pauseUpdates();
            minuteItems.forEach((minuteItem) =>
                dispatch(
                    onMinuteItemDeleteSuccess(minuteItem.occurrence, minuteItem)
                )
            );
            resumeUpdates();
        },

        ['occurrence:minutes:status'](ctx, occurrenceId, minuteItem) {
            dispatch({
                type: TOGGLE_OCCURRENCE_MINUTES_STATUS_WEBSOCKET,
                occurrenceId,
                minuteItems: [minuteItem],
            });
        },

        ['occurrence:minutes:renumbered'](ctx, occurrenceId, minuteItems) {
            dispatch({
                type: RENUMBER_OCCURRENCE_MINUTES_WEBSOCKET,
                occurrenceId,
                minuteItems,
            });
        },

        ['occurrence:minutes:documents_linked'](ctx, minuteItem, docs) {
            dispatch({
                type: LINK_DOCUMENTS_WEBSOCKET,
                minuteItem: minuteItemModel(minuteItem),
                docs,
            });
        },

        ['occurrence:minutes:documents_unlinked'](ctx, minuteItem, docs) {
            dispatch({
                type: UNLINK_DOCUMENTS_WEBSOCKET,
                minuteItem: minuteItemModel(minuteItem),
                docs,
            });
        },

        ['occurrence:attendance:update'](ctx, payload) {
            dispatch({
                type: UPDATE_OCCURRENCE_ATTENDANCE_WEBSOCKET,
                occurrenceId: payload.id,
                attendee: payload.attendee,
            });
        },

        ['document:create'](ctx, payload) {
            dispatch(documentUploadSuccess([payload]));
        },

        ['document:update'](ctx, payload) {
            dispatch(editDocument(payload));
        },

        ['document:delete'](ctx, payload) {
            dispatch(deleteDocumentSuccess(payload));
        },

        ['private-note:create'](ctx, payload) {
            dispatch(onPrivateNotesCreateSuccess(payload.occurrence, payload));
        },

        ['private-note:update'](ctx, payload) {
            dispatch(onPrivateNotesEditSuccess(payload.occurrence, payload));
        },

        ['user:update'](ctx, userProfile) {
            const { user } = getState();

            if (userProfile.id === user.id) {
                dispatch({
                    type: UPDATE_USERPROFILE_WEBSOCKET,
                    userProfile,
                });
                const compareFields = ['locale', 'timezone'];
                if (
                    !_isEqual(
                        _pick(user, compareFields),
                        _pick(userProfile, compareFields)
                    )
                ) {
                    clearCacheMeetingAddEditFormInitialValues();
                    clearCacheOccurrenceAddEditFormInitialValues();
                }

                return;
            }

            dispatch({
                type: UPDATE_USERPROFILE_OTHERUSER_WEBSOCKET,
                userProfile,
            });
        },

        ['permission:create'](ctx, payload) {
            // if the permission create (of type meeting) came from another user, force the load of the meeting data, as it's probably not in the user's session.
            // this is critical when a user's workspace request is accepted as part of being invited to a meeting.
            const createMeta =
                payload.targetType !== PERMISSION_TYPE_MEETING ||
                (ctx.user?.id === getState().user.id
                    ? null
                    : { forceReload: true });
            dispatch(onPermissionCreate(payload, createMeta));
        },

        ['permission:update'](ctx, payload) {
            dispatch(onPermissionUpdate(payload));
        },

        ['permission:delete'](ctx, payload) {
            dispatch(onPermissionDelete(payload));
        },

        ['integration:profile:create'](ctx, profile) {
            if (
                profile.integrationType === INTEGRATION_TYPE_CALENDARS &&
                isIntegrationCalendarSettingsPage()
            )
                pushTo(ROUTES.dashboardSchedule);

            if (isCRMIntegration(profile)) {
                dispatch(loadCRMLinks());
            }
            dispatch({
                type: INTEGRATION_PROFILE_CREATED,
                profile,
            });
        },

        ['integration:profile:update'](ctx, profile, prev) {
            const { remoteResources, pastWeeks, futureWeeks } = profile;
            const {
                remoteResources: prevRemoteResources,
                pastWeeks: prevPastWeeks,
                futureWeeks: prevFutureWeeks,
            } = prev || {};
            const { isActive, statusConnected, statusReconnected } =
                integrationProfileStatusMeta(profile, prev);

            if (isCalendarIntegration(profile)) {
                if (
                    isActive &&
                    (statusConnected ||
                        !_isEqual(remoteResources, prevRemoteResources) ||
                        !_isEqual(pastWeeks, prevPastWeeks) ||
                        !_isEqual(futureWeeks, prevFutureWeeks))
                ) {
                    if (isIntegrationCalendarSettingsPage())
                        pushTo(ROUTES.dashboardSchedule);

                    dispatch(
                        loadRemoteCalendarEvents(profile, {
                            dispatchImmediately: true,
                        })
                    );

                    // for any calendar turned off, remove any events from the redux store
                    for (const [calendar, prevdata] of Object.entries(
                        prevRemoteResources || {}
                    )) {
                        if (
                            prevdata.enabled &&
                            !remoteResources[calendar]?.enabled
                        ) {
                            dispatch(
                                resetRemoteCalendarEvents(
                                    profile.authSource,
                                    calendar
                                )
                            );
                        }
                    }
                }
                !isActive &&
                    dispatch(resetRemoteCalendarEvents(profile.authSource));
            }
            if (isCRMIntegration(profile) && statusReconnected) {
                dispatch(loadCRMLinks());
            }
            if (isAuthenticationIntegration(profile)) {
                const detailPage = isDetailPage(location.pathname);
                if (detailPage) {
                    // user is viewing a meeting and there's an update to their auth profile.
                    // if the scope of their file permissions has changed, check the access
                    const { scopes } = getFilesConfig(profile);
                    const hasFileAccess = hasIntegrationProfileGotScopes(
                        profile,
                        scopes
                    );
                    const hadFileAccess = hasIntegrationProfileGotScopes(
                        prev,
                        scopes
                    );
                    if (scopes?.length > 0 && hasFileAccess !== hadFileAccess) {
                        const meeting = getPageState(
                            getState(),
                            detailPage.id
                        )?.meeting;
                        dispatch(verifyFileLinkForMeeting({ id: meeting }));
                    }
                }
            }
            dispatch({
                type: INTEGRATION_PROFILE_UPDATED,
                profile,
                previousProfile: prev,
            });
        },

        ['integration:sync:complete'](ctx, payload) {
            dispatch(getLinkCount(payload));
        },

        [`integration:links:${INTEGRATION_TYPE_TASKS}:deleted`](ctx, payload) {
            dispatch(getLinkCount(payload));
        },

        [`integration:links:${INTEGRATION_TYPE_CALENDARS}:created`](
            ctx,
            profile,
            links
        ) {
            dispatch({
                type: INTEGRATION_CALENDAR_LINKS_CREATED,
                profile,
                links,
            });
        },
        [`integration:links:${INTEGRATION_TYPE_CALENDARS}:deleted`](
            ctx,
            profile,
            links
        ) {
            dispatch({
                type: INTEGRATION_CALENDAR_LINKS_DELETED,
                profile,
                links,
            });
            links
                .filter((link) => link.isHidden)
                .forEach((link) =>
                    dispatch({
                        type: REMOTE_CALENDAR_EVENT_HIDDEN,
                        calendarType: profile.authSource,
                        isHidden: false,
                        id: link.remoteId,
                    })
                );
        },
        [`integration:links:${INTEGRATION_TYPE_CRM}:created`](
            ctx,
            profile,
            links
        ) {
            dispatch({
                type: INTEGRATION_CRM_LINKS_LOADED,
                links,
            });
        },
        [`integration:link:${INTEGRATION_TYPE_CRM}:updated`](ctx, link, prev) {
            // CRM link is updated to point to a different workspace, then clear out the customerList
            // NOTE: it's important that this runs before the INTEGRATION_CRM_LINKS_LOADED, as the WORKSPACE_CRM_ACCESS_REMOVED will remove the existing link
            if (prev.workspaceId && link.workspaceId !== prev.workspaceId) {
                dispatch({
                    type: WORKSPACE_CRM_ACCESS_REMOVED,
                    workspace: { id: prev.workspaceId },
                });
            }
            dispatch({
                type: INTEGRATION_CRM_LINKS_LOADED,
                links: [link],
            });
        },
        [`integration:links:${INTEGRATION_TYPE_CRM}:deleted`](
            ctx,
            profile,
            links
        ) {
            dispatch({
                type: INTEGRATION_CRM_LINKS_DELETED,
                profile,
                links,
            });
            // if integration has been disconnected, remove all the crm data
            const connectionLink = links.find(
                (link) => link.workspaceId && !link.remoteSubscriptionId
            );
            connectionLink &&
                dispatch({
                    type: WORKSPACE_CRM_ACCESS_REMOVED,
                    workspace: { id: connectionLink.workspaceId },
                });
        },
        [`integration:events:updated`](ctx, profile, uid, { events, users }) {
            dispatch({
                type: REMOTE_CALENDAR_EVENTS_UPDATED,
                profile,
                uid,
                events: eventModelList(events),
                users,
            });
        },
        [`integration:events:deleted`](ctx, profile, eventIdList) {
            dispatch({
                type: REMOTE_CALENDAR_EVENTS_DELETED,
                profile,
                eventIdList,
            });
        },
        [`integration:remotecrm:customers:updated`](
            ctx,
            { authSource, workspaceId }
        ) {
            dispatch(loadCRMInfo({ authSource, workspaceId }));
        },
        [`integration:remotecrm:customer:deleted`](
            ctx,
            { authSource, workspaceId, customerId }
        ) {
            dispatch({
                type: CRM_CUSTOMER_DELETED,
                authSource,
                workspaceId,
                customerId,
            });
        },

        ['notification:create'](ctx, payload) {
            dispatch(onNotificationCreateSuccess(notificationModel(payload)));
        },

        ['notification:update'](ctx, payload) {
            dispatch(onNotificationUpdateSuccess(notificationModel(payload)));
        },

        ['notification:delete'](ctx, payload) {
            dispatch({
                type: NOTIFICATION_DELETE,
                notification: payload,
            });
        },

        ['pageview:create'](ctx, pageView) {
            // these updates cause jerky behaviour in the app when navigating between changes.
            // but really, the updates don't need to be displayed to the user immediately... i.e. they don't really need to trigger redux updates.
            // so we'll hold updates on this so they don't get published immediately.
            pauseUpdates();
            dispatch({
                type: PAGEVIEW_CREATED,
                pageView: pageViewModel(pageView),
            });
            resumeUpdatesQuiet();
        },
        ['pageview:update'](ctx, pageView) {
            // these updates cause jerky behaviour in the app when navigating between changes.
            // but really, the updates don't need to be displayed to the user immediately... i.e. they don't really need to trigger redux updates.
            // so we'll hold updates on this so they don't get published immediately.
            pauseUpdates();
            dispatch({
                type: PAGEVIEW_UPDATED,
                pageView: pageViewModel(pageView),
            });
            resumeUpdatesQuiet();
        },
        ['pageview:meta'](ctx, pageViews) {
            dispatch({
                type: PAGEVIEW_META_UPDATED,
                pageViews: pageViewModelList(pageViews),
            });
        },

        ['server:time'](ctx, payload) {
            setLastEventTime(payload.time);
            dispatch(checkClientVersion(payload));
        },

        ['connect']() {
            setLastEventTime(moment().unix());
            wsPublish('server:time');
            dispatch(
                removeAlert({
                    id: 'websocket-disconnected',
                })
            );
        },

        ['disconnect']() {
            closeEventReplayEnd?.cancel?.();
            dispatch({
                type: WEBSOCKET_DISCONNECTED,
            });
            onDisconnect();
        },

        ['user:page:ack'](ctx, payload) {
            const { user } = ctx;
            dispatch({
                type: USER_JOIN_WEBSOCKET,
                user,
                ...payload,
                isTyping: false,
            });
        },

        ['user:page:join'](ctx, payload) {
            const location = window.tinymce?.activeEditor?.hasFocus()
                ? window.tinymce.activeEditor.selection.getBookmark(2)
                : null;
            wsPublish(`client:user:page:ack`, {
                socketId: payload.socketId,
                location,
            });

            const { user } = ctx;
            dispatch({
                type: USER_JOIN_WEBSOCKET,
                user,
                ...payload,
                isTyping: false,
            });
        },

        ['user:page:topic:change'](ctx, payload) {
            // When the logged in user changes topic, as they are registered in the room they also get the event.
            // However the logged in user's socket is not registered in redux store (because they don't get a user:page:join for their own joining event),
            // so dispatching this event will not add the logged in user to the redux "active users".
            // We don't want to filter out based on ctx.user.id === store.user.id because the logged in user may have multiple sessions,
            // and we do want to display their other session.
            const { user } = ctx;
            dispatch({
                type: USER_TOPIC_CHANGE_WEBSOCKET,
                user,
                ...payload,
                isTyping: false,
            });
        },

        ['user:page:topic:typing'](ctx, payload) {
            // When the logged in user changes topic, as they are registered in the room they also get the event.
            // However the logged in user's socket is not registered in redux store (because they don't get a user:page:join for their own joining event),
            // so dispatching this event will not add the logged in user to the redux "active users".
            // We don't want to filter out based on ctx.user.id === store.user.id because the logged in user may have multiple sessions,
            // and we do want to display their other session.
            const { user } = ctx;
            dispatch({
                type: USER_TYPING_WEBSOCKET,
                user,
                ...payload,
                isTyping: true,
            });
        },
        ['user:page:topic:activeLocation'](ctx, payload) {
            const { user } = ctx;
            dispatch({
                type: USER_ACTIVELOCATION_WEBSOCKET,
                user,
                ...payload,
            });
        },

        ['user:page:leave'](ctx, payload) {
            const { user } = ctx;
            dispatch({
                type: USER_LEAVE_WEBSOCKET,
                user,
                ...payload,
            });
        },

        ['workspace:user:update'](ctx, workspaceId, member) {
            dispatch({
                type: WORKSPACE_MEMBER_UPDATED,
                workspaceId,
                member,
            });
        },

        ['workspace:user:permission:create'](ctx, workspaceId, member) {
            dispatch({
                type: WORKSPACE_MEMBER_ADDED,
                workspaceId,
                member,
            });
        },

        ['workspace:user:permission:update'](ctx, workspaceId, member) {
            dispatch({
                type: WORKSPACE_MEMBER_UPDATED,
                workspaceId,
                member,
            });
        },

        ['workspace:user:permission:delete'](ctx, workspaceId, member) {
            dispatch({
                type: WORKSPACE_MEMBER_DELETED,
                workspaceId,
                member,
            });
        },

        ['workspace:members:reset'](ctx, workspaceId) {
            const { members } =
                getDashboardStateForWorkspace(getState(), workspaceId) || {};

            if (members) {
                dispatch({
                    type: WORKSPACE_MEMBER_LIST_RESET,
                    workspaceId,
                });

                getWorkspaceAccessLevel(workspaceId).canUpdate &&
                    dispatch(loadWorkspacePermissions(workspaceId));
            }
        },

        ['workspace:usage:create'](ctx, workspaceUsage) {
            dispatch({
                type: WORKSPACE_INVOICE_CREATED,
                workspaceId: workspaceUsage.workspaceId,
                invoice: workspaceInvoice(workspaceUsage),
            });
        },
        ['workspace:usage:update'](ctx, workspaceUsage) {
            dispatch({
                type: WORKSPACE_INVOICE_UPDATED,
                workspaceId: workspaceUsage.workspaceId,
                invoice: workspaceInvoice(workspaceUsage),
            });
        },
        ['connect_error'](error) {
            ['AuthenticationError', 'UserNotFoundException'].includes(
                error?.message
            ) && dispatch(onInvalidSessionError());
        },
        ['occurrence:activity:reload'](ctx, occurrenceId, activityId) {
            const pageState = getPageState(getState(), occurrenceId);
            if (pageState) {
                if (prevActivityOccurrenceId !== occurrenceId) {
                    onOccurrenceActivityUpdate.flush();
                    prevActivityOccurrenceId = occurrenceId;
                }
                onOccurrenceActivityUpdate(occurrenceId);
            }
            const activityItems = pageState?.activities;
            if (!activityItems) return;
            const occurrenceActivityId = `${occurrenceId}.${activityId}`;
            if (prevLoadOccurrenceActivityId !== occurrenceActivityId) {
                throttleLoadOccurrenceActivity.flush();
                prevLoadOccurrenceActivityId = occurrenceActivityId;
            }
            throttleLoadOccurrenceActivity(activityId, occurrenceId);
        },
        ['user:update:password']() {
            dispatch(onUserDetailsUpdatedNotification());
        },
    };
}

// if there are some events that, for example, may initiate a callback to the api to get additional data,
// we may want to dedup these events when replaying, so that they only get run once.
// this object should have a key for the event to be deduped, and then a dedup function you can run on the event data.
// When replaying events, the first event of this type will get executed, and subsequent events will be dropped/ignored.
const deDupEvents = {
    ['integration:remotecrm:customers:updated']: (event, playedEvents) => {
        const [key, , ...args] = event;
        return playedEvents.some((playedEvent) => {
            const [playedKey, , ...playedArgs] = playedEvent;
            return key === playedKey && _isEqual(args, playedArgs);
        });
    },
};

export function getReplayEvents() {
    return async (dispatch, getState) => {
        try {
            const events = await get('/api/preload/events/since', {
                time: getLastEventTime(),
            });

            const subscriptions = createSocketSubscriptions(dispatch, getState);

            const playedEvents = [];

            for (const ev of events) {
                const [key, ctx, ...args] = ev;

                if (deDupEvents[key]?.(ev, playedEvents)) continue;

                playedEvents.push(ev);

                if (subscriptions[key]) {
                    console.log('Replay event:', key, new Date(), ctx, ...args); // eslint-disable-line no-console
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                    ctx && ctx.eventTime && setLastEventTime(ctx.eventTime);
                    await subscriptions[key](ctx, ...args);
                }
            }
        } catch {
            // May be if the authorisation fails, server is unavailable, version expired
            // errors are handled in the get function
            // TODO: issue here.
            // the errors for this are *not* handled in the get function.
            // we need to redirect the user to the login screen, particularly if unauthorised.
            // it makes the user think that everything is OK, when it's really not.
            // actually, maybe the error type thrown by the server is not AuthenticationError
        }
    };
}

function createManagerSubscriptions(dispatch, getState) {
    return {
        async ['reconnect']() {
            // cancel any pending disconnect debounce, so the disconnect message does not show.
            onDisconnect.cancel();

            const time = getLastEventTime();
            // if time since last connection has been greater than 3 days, reload the browser to make sure they get all the latest data from server.
            // this is because websocket events are only stored on the server for 3 days, so replay will not be possible.
            const timeSinceLastEventInSeconds = moment().diff(
                moment.unix(time),
                'seconds',
                true
            );

            if (timeSinceLastEventInSeconds > THREE_DAYS_IN_SECONDS) {
                // eslint-disable-next-line no-console
                console.log(
                    `time since last event (seconds): ${timeSinceLastEventInSeconds}. Reloading browser.`
                );

                if (window.Intercom) {
                    window.Intercom('shutdown');
                }

                dispatch(unsubscribe());

                window.location.reload();
                return;
            }

            dispatch(
                removeAlert({
                    id: 'websocket-disconnected',
                })
            );
            dispatch(
                alertSuccess({
                    id: 'websocket-reconnected',
                    title: 'Connected to MinuteMe',
                })
            );

            const { pathname } = history.location;
            const workspacePage = getWorkspacePathParams(pathname);
            const detailPage = isDetailPage(pathname);
            if (detailPage) {
                // If user is on a meeting detail page, send the websocket reconnect event
                const pageState = getPageState(getState(), detailPage.id);

                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                pageState &&
                    pageState.occurrence &&
                    wsPublish(`client:user:page:join:reconnect`, {
                        activePage: {
                            objectType: 'occurrence',
                            state: {
                                occurrence: pageState.occurrence,
                                meeting: pageState.meeting,
                                minuteItemIds: [detailPage.minuteItemId],
                            },
                        },
                    });
            } else if (workspacePage.length) {
                // user is on a workspace page, send the websocket join event
                const workspaceId = workspacePage[2];
                workspaceId &&
                    wsPublish(`client:user:workspace:join`, { workspaceId });
            }

            if (time) {
                dispatch(
                    removeAlert({
                        id: 'websocket-reconnected',
                    })
                );
                dispatch(
                    alertPersistedInfo({
                        id: 'event-replay-start',
                        title: 'Synchronising with MinuteMe…',
                        message: `Making sure you have the most up-to-date info.`,
                    })
                );

                pauseUpdates();

                dispatch({
                    type: EVENT_REPLAY_START,
                });

                // TODO what if this fails because of 401 error???
                await dispatch(getReplayEvents());

                dispatch(
                    removeAlert({
                        id: 'event-replay-start',
                    })
                );
                dispatch({
                    type: EVENT_REPLAY_DONE,
                });

                resumeUpdatesQuiet();

                dispatch(
                    alertSuccess({
                        id: 'event-replay-end',
                        title: 'Reconnected and up-to-date',
                        message: `You have the most current data.`,
                    })
                );
                // because the toaster doesn't always close automatically, this is a notification that can often stay open.
                // so force it to close after a timeout, if it's not already closed.
                closeEventReplayEnd();
            }
        },
    };
}

export function subscribe() {
    return (dispatch, getState) => {
        wsSubscribe(
            createSocketSubscriptions(dispatch, getState),
            createManagerSubscriptions(dispatch, getState)
        );

        return {
            type: WEBSOCKET_SUBSCRIBE,
        };
    };
}

export function unsubscribe() {
    wsUnsubscribe();
    return {
        type: WEBSOCKET_UNSUBSCRIBE,
    };
}

export function publish(message) {
    wsPublish('client:message', message);
}
